const theme = {
  colors: {
    accent: "#0089ff",
    backgroundColor: "#0a0e13",
    backgroundColorDark: "#000",
  },
};

export type Theme = typeof theme;

export default theme;
