import TeamMatchupTile from "./TeamMatchupTile";

import styled from "@emotion/styled";
import { motion, MotionProps, MotionStyle } from "framer-motion";
import { Theme } from "../../../theme/theme";
import { css } from "@emotion/react";
import Matchup from "../../../../domain/models/Matchup";
import { Fragment } from "react";

interface MatchupTileProps {
  matchup: Matchup;
  style?: MotionStyle;
  requiredPoints: number;
}

const MatchupTile = ({
  matchup,
  requiredPoints,
  ...props
}: MatchupTileProps & MotionProps) => (
  <Container {...props}>
    {matchup.competitors.map((competitor, idx) => (
      <Fragment key={`${competitor.team}+${competitor.score}`}>
        {idx > 0 && <hr />}
        <TeamMatchupTile
          competitor={competitor}
          active={competitor.score >= requiredPoints}
        />
      </Fragment>
    ))}
  </Container>
);

const Container = styled(motion.div)<{ theme?: Theme }>`
  transition: 320ms all;

  hr {
    margin: 0px;
    border-color: rgba(255, 255, 255, 0.1);
  }

  ${({ theme: { colors } }) => css`
    border: 1px solid transparent;

    :hover {
      border-color: ${colors.accent};
    }
  `}
`;

export default MatchupTile;
