import Bracket from "../../domain/models/Bracket";
import Team from "../../domain/models/Team";
import Matchup, { CompetitorMatchup } from "../../domain/models/Matchup";
import Tournament from "../../domain/models/Tournament";
import {
  Disposer,
  TournamentDataSource,
} from "../../infra/datasource/TournamentDataSource";

import Firebase from "./Firebase";
import Game from "../../domain/models/Game";

export default class FirebaseTournameDataSource extends TournamentDataSource {
  async getGameInfo(gameId: string): Promise<Game> {
    const doc = await Firebase.firestore().doc(`games/${gameId}`).get();
    return { id: doc.id, ...(doc.data() as Omit<Game, "id">) };
  }

  protected get tournament() {
    return Firebase.firestore().doc(`/tournaments/${this.tournamentId}`);
  }

  subscribeTo(subscriber: (tournament: Tournament) => void): Promise<Disposer> {
    throw new Error("Method not implemented.");
  }

  async subscribeToBrackets(
    gameId: string,
    subscriber: (brackets: Bracket[]) => void
  ): Promise<Disposer> {
    return this.tournament
      .collection(`brackets`)
      .where("gameId", "==", gameId)
      .withConverter(BracketConverter)
      .onSnapshot(async (snapshot) => {
        const brackets = await Promise.all(
          snapshot.docs.map(async (bracket) => {
            const data = bracket.data();

            const rawMatchups = await this.tournament
              .collection(`brackets/${data.id}/matchups`)
              .get();

            const matchups = await Promise.all<Matchup>(
              rawMatchups.docs.map(async (matchup) => {
                const { competitors: rawCompetitors } = matchup.data();

                const competitors = await Promise.all<CompetitorMatchup>(
                  rawCompetitors.map(async ({ team, score }: any) => ({
                    score,
                    team: await team.get().then((t: any) => t.data() as Team),
                  }))
                );

                return { id: matchup.id, competitors };
              })
            );

            return { ...data, matchups } as Bracket;
          })
        );

        subscriber(brackets);
      });
  }
}

const BracketConverter = {
  fromFirestore: (
    snapshot: any
  ): Pick<Bracket, "id" | "gameId" | "position"> => {
    return { id: snapshot.id, ...snapshot.data() };
  },
  toFirestore: (bracket: Bracket) => {
    return bracket;
  },
};
