import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { CompetitorMatchup } from "../../../../domain/models/Matchup";

import { Theme } from "../../../theme/theme";

export interface TeamMatchupTileProps {
  active?: boolean;
  competitor: CompetitorMatchup;
}

const TeamMatchupTile = ({ active, competitor }: TeamMatchupTileProps) => (
  <Container active={active} style={{ opacity: active ? 1 : 0.6 }}>
    <TeamLogo url={competitor.team.image} />
    <TeamName>{competitor.team.code}</TeamName>
    <TeamScore>{competitor.score}</TeamScore>
  </Container>
);

const Container = styled(motion.a)<{ theme?: Theme; active?: boolean }>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  color: white;

  :before {
    width: 4px;
    content: "";
    height: 100%;
    flex-shrink: 0;
    transition: 320ms all;

    background-color: ${({ theme: { colors }, active }) =>
      active ? colors.accent : "#1d1d1d"};
  }

  ${({ theme: { colors } }) => css`
    background-color: ${colors.backgroundColorDark};
  `}
`;

const TeamName = styled.span`
  flex: 1;
  font-weight: 300;
  font-family: Montserrat;
`;

const TeamScore = styled.span`
  width: 36px;
  height: 36px;
  margin: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-family: Montserrat;
`;

const TeamLogo = styled.div<{ url: string }>`
  width: 36px;
  height: 36px;
  margin: 12px;

  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export default TeamMatchupTile;
