import Game from "../../domain/models/Game";
import Tournament from "../../domain/models/Tournament";

export type Disposer = () => void;

export abstract class TournamentDataSource {
  constructor(protected tournamentId: string) {}

  abstract subscribeTo(
    subscriber: (tournament: Tournament) => void
  ): Promise<Disposer>;

  // abstract subscribeToBrackets(
  //   gameId: string,
  //   subscriber: (brackets: Bracket[]) => void
  // ): Promise<Disposer>;

  abstract getGameInfo(gameId: string): Promise<Game>;
}
