import { ThemeProvider } from "@emotion/react";
import React from "react";
import Brackets from "./pages/Brackets/Brackets";
import theme from "./theme/theme";

const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Brackets />
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
