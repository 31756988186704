import { useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Observer } from "mobx-react";
import { makeObservable, observable, runInAction } from "mobx";

import { Theme } from "../../theme/theme";
import Bracket from "../../../domain/models/Bracket";
import FirebaseTournameDataSource from "../../../external/datasource/FirebaseTournamentDataSource";

import MatchupTile from "./components/MatchupTile";
import Game from "../../../domain/models/Game";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

class BracketStore {
  @observable
  public brackets: Bracket[] = [];

  @observable
  public loading: boolean = true;

  @observable
  public game?: Game;

  constructor(private tournamentId: string, private gameId: string) {
    makeObservable(this);

    const tournamentsDataSource = new FirebaseTournameDataSource(
      this.tournamentId
    );

    tournamentsDataSource.subscribeToBrackets(this.gameId, (brackets) => {
      runInAction(() => (this.brackets = brackets));
    });

    tournamentsDataSource
      .getGameInfo(this.gameId)
      .then((game) => {
        runInAction(() => {
          this.game = game;
        });
      })
      .finally(() => {
        runInAction(() => (this.loading = false));
      });
  }
}

const Brackets = () => {
  const { current: bracketStore } = useRef(new BracketStore("2021", "hots"));

  return (
    <AnimateSharedLayout>
      <Observer>
        {() => {
          if (bracketStore.loading) {
            return <Content>Loading...</Content>;
          }

          return (
            <Content style={{ padding: 32 }}>
              <AnimatePresence presenceAffectsLayout>
                {bracketStore.brackets.map((bracket, idx) => (
                  <RoundContainer
                    layout
                    key={bracket.id}
                    transition={{ staggerChildren: 10 }}
                    style={{ marginRight: 32, marginLeft: idx > 0 ? 32 : 0 }}
                  >
                    {bracket.matchups.map((matchup, i) => (
                      <MatchupTile
                        layout
                        key={matchup.id}
                        matchup={matchup}
                        layoutId={matchup.id}
                        exit={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        initial={{ opacity: 0, x: -30 }}
                        style={{ marginTop: i > 0 ? 32 : 0 }}
                        requiredPoints={
                          bracketStore.game?.matchupRequiredPoints || 0
                        }
                      />
                    ))}
                  </RoundContainer>
                ))}
              </AnimatePresence>
            </Content>
          );
        }}
      </Observer>
    </AnimateSharedLayout>
  );
};

const Content = styled.main<{ theme?: Theme }>`
  height: 100%;
  display: flex;
  overflow: auto;

  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundColor};
  `}
`;

const RoundContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;

  min-width: 440px;
`;

export default Brackets;
