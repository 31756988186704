import firebase from "firebase";

const firebaseConfig = {
  projectId: "evo-sherep",
  messagingSenderId: "948268116696",
  storageBucket: "evo-sherep.appspot.com",
  authDomain: "evo-sherep.firebaseapp.com",
  apiKey: "AIzaSyCU-XMA_Dzm9NKx0OACvqasNTg6OV72fk8",
  appId: "1:948268116696:web:a0c84baade7802312fc5ad",
};

const Firebase = firebase.initializeApp(firebaseConfig);

export default Firebase;
